import {
    IUser,
    IReportees,
    ISelectFilter,
    IStatusWithResponse,
    IContextUserDetail,
    IOwnerSelectFilter,
} from '../interfaces';
import { IExecutiveReportees } from '../interfaces/DashboardInterface';
import { getUserFullName, getAPIStatus, reloadPageForUnauthorized } from '../utils';

const getAllUser = async (): Promise<IUser[] | undefined> => {
    try {
        const usersObj = await fetch(`${process.env.REACT_APP_API_HOST}/v1/users`, {
            headers: {
                Authorization: ` Bearer ${localStorage.getItem('token')}`,
            },
        });
        reloadPageForUnauthorized(usersObj);
        const usersResponse = await usersObj.json();
        const users: IUser[] = [];
        for (const user of usersResponse) {
            users.push({
                employeeId: user.id,
                email: user.email,
                role: user.role,
                location: user.location,
                firstName: user.firstName,
                lastName: user.lastName,
                vpId: user.vpId,
                managerId: user.managerId,
                employeeName: `${getUserFullName(user.firstName, user.lastName)}`,
                managerName: user.manager ? `${getUserFullName(user.manager.firstName, user.manager.lastName)}` : '',
                vpName: ``,
            });
        }

        return users;
    } catch (err) {
        console.log(err);
    }
};

const getUser = async (mailId: string): Promise<IContextUserDetail | undefined> => {
    try {
        const userObj = await fetch(`${process.env.REACT_APP_API_HOST}/v1/users/by-email?email=${mailId}`, {
            headers: {
                Authorization: ` Bearer ${localStorage.getItem('token')}`,
            },
        });
        const [user] = await userObj.json();
        const userResponse: IContextUserDetail = {
            employeeId: user.id,
            email: user.email,
            role: user.role,
            employeeName: `${getUserFullName(user.firstName, user.lastName)}`,
            managerName: user.manager ? `${getUserFullName(user.manager.firstName, user.manager.lastName)}` : '',
            isManager: user.isManager,
            isExecutive: user.isExecutive,
            hasSubordinates: user.hasSubordinates,
        };

        return userResponse;
    } catch (err) {
        console.log(err);
    }
};

const getEmployeesOfManger = async (managerId: number): Promise<ISelectFilter[] | undefined> => {
    try {
        const userObj = await fetch(`${process.env.REACT_APP_API_HOST}/v1/users/by-manager?manager=${managerId}`, {
            headers: {
                Authorization: ` Bearer ${localStorage.getItem('token')}`,
            },
        });
        reloadPageForUnauthorized(userObj);
        const users = await userObj.json();
        const employees: IOwnerSelectFilter[] = [];
        for (const user of users) {
            employees.push({
                label: `${getUserFullName(user.firstName, user.lastName)}`,
                value: user.id,
                email: user.email,
            });
        }

        return employees;
    } catch (err) {
        console.log(err);
    }
};

const getEmployeesOfExecutive = async (executiveId: number): Promise<ISelectFilter[] | undefined> => {
    try {
        const userObj = await fetch(
            `${process.env.REACT_APP_API_HOST}/v1/users/by-executive?executive=${executiveId}`,
            {
                headers: {
                    Authorization: ` Bearer ${localStorage.getItem('token')}`,
                },
            },
        );
        reloadPageForUnauthorized(userObj);
        const users = await userObj.json();
        const employees: ISelectFilter[] = [];
        // console.log(users.employee);
        for (const user of users) {
            employees.push({
                label: `${getUserFullName(user.employee.firstName, user.employee.lastName)}`,
                value: user.employee.id,
            });
        }

        return employees;
    } catch (err) {
        console.log(err);
    }
};

const getReporteesGoalInfo = async (year: number, managerId: number): Promise<IReportees[] | undefined> => {
    try {
        const userObj = await fetch(
            `${process.env.REACT_APP_API_HOST}/v1/goals/reportee-goal-status?year=${year}&reviewer_Id=${managerId}`,
            {
                headers: {
                    Authorization: ` Bearer ${localStorage.getItem('token')}`,
                },
            },
        );
        reloadPageForUnauthorized(userObj);
        const employeeGoals = await userObj.json();
        const directReports: IReportees[] = [];
        for (const goal of employeeGoals) {
            directReports.push({
                name: getUserFullName(goal.employee.firstName, goal.employee.lastName),
                empId: goal.employeeId,
                score: goal.totalPoints ? goal.totalPoints - goal.totalLoss : 0,
                status: goal.status,
                date: new Date(parseInt(goal.updatedAt)),
                version: goal.versionNumber,
                points: goal.totalPoints,
                quarter: goal.cycle.quarter,
                year: goal.cycle.year,
            });
        }

        return directReports;
    } catch (err) {
        console.log(err);
    }
};

const getExecutiveReporteesGoalInfo = async (
    year: number,
    executiveId: number,
): Promise<IExecutiveReportees[] | undefined> => {
    try {
        const userObj = await fetch(
            `${process.env.REACT_APP_API_HOST}/v1/goals/executive-goal-status?year=${year}&executive_Id=${executiveId}`,
            {
                headers: {
                    Authorization: ` Bearer ${localStorage.getItem('token')}`,
                },
            },
        );
        reloadPageForUnauthorized(userObj);
        const employeeGoals = await userObj.json();
        const directReports: IExecutiveReportees[] = [];
        for (const goal of employeeGoals) {
            directReports.push({
                id: goal.id,
                name: getUserFullName(goal.employee.firstName, goal.employee.lastName),
                empId: goal.employeeId,
                score: goal.totalPoints ? goal.totalPoints - goal.totalLoss : 0,
                status: goal.status,
                date: new Date(parseInt(goal.updatedAt)),
                version: goal.versionNumber,
                points: goal.totalPoints,
                quarter: goal.cycle.quarter,
                year: goal.cycle.year,
                manager: getUserFullName(goal.reviewer.firstName, goal.reviewer.lastName),
            });
        }

        return directReports;
    } catch (err) {
        console.log(err);
    }
};

const userAutoSearch = async (query: string, role = ''): Promise<ISelectFilter[] | undefined> => {
    try {
        const usersObj = await fetch(`${process.env.REACT_APP_API_HOST}/v1/users/search/?query=${query}&role=${role}`, {
            headers: {
                Authorization: ` Bearer ${localStorage.getItem('token')}`,
            },
        });
        reloadPageForUnauthorized(usersObj);
        const usersResponse = await usersObj.json();
        const usersList: IOwnerSelectFilter[] = [];
        for (const user of usersResponse) {
            usersList.push({
                label: getUserFullName(user.firstName, user.lastName),
                value: user.id,
                email: user.email,
            });
        }

        return usersList;
    } catch (err) {
        console.log(err);
    }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const addUser = async (formData: unknown, year: number, quarter: string): Promise<IStatusWithResponse | undefined> => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: ` Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify(formData),
        };
        const userResponse = await fetch(`${process.env.REACT_APP_API_HOST}/v1/users/`, requestOptions);
        reloadPageForUnauthorized(userResponse);
        const res = userResponse.clone();
        const userObj = await userResponse.json();
        await setCyclesForUsers({
            year,
            quarter,
            employees: [userObj.id],
        });
        return { status: getAPIStatus(userResponse.status), response: res };
    } catch (err) {
        console.log(err);
    }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const setCyclesForUsers = async (formData: unknown): Promise<IStatusWithResponse | undefined> => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: ` Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify(formData),
        };
        const cycleResponse = await fetch(`${process.env.REACT_APP_API_HOST}/v1/cycles/employees/`, requestOptions);
        reloadPageForUnauthorized(cycleResponse);
        return { status: getAPIStatus(cycleResponse.status), response: cycleResponse };
        // return getAPIStatus(userResponse.status);
    } catch (err) {
        console.log(err);
    }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getUsersWithoutGoals = async (year: number, quarter: string): Promise<IUser[] | undefined> => {
    try {
        const usersObj = await fetch(
            `${process.env.REACT_APP_API_HOST}/v1/users/without-goals?year=${year}&quarter=${quarter}`,
            {
                headers: {
                    Authorization: ` Bearer ${localStorage.getItem('token')}`,
                },
            },
        );
        reloadPageForUnauthorized(usersObj);
        const usersResponse = await usersObj.json();
        const users: IUser[] = [];
        for (const user of usersResponse) {
            users.push({
                employeeId: user.id,
                email: user.email,
                role: user.role,
                location: user.location,
                firstName: user.first_name,
                lastName: user.last_name,
                vpId: user.vpId,
                managerId: user.managerId,
                employeeName: `${getUserFullName(user.first_name, user.last_name)}`,
                // eslint-disable-next-line prettier/prettier
                managerName: `${
                    user.ManagerFirstName ? getUserFullName(user.ManagerFirstName, user.ManagerLastName) : '-'
                }`,
                vpName: `${user.VFirstName ? getUserFullName(user.VFirstName, user.VLastName) : '-'}`,
            });
        }

        return users;
    } catch (err) {
        console.log(err);
    }
};

const getUsersForExecutiveReview = async (year: number, quarter: string): Promise<IUser[] | undefined> => {
    try {
        const usersObj = await fetch(
            `${process.env.REACT_APP_API_HOST}/v1/users/for-executive-review?year=${year}&quarter=${quarter}`,
            {
                headers: {
                    Authorization: ` Bearer ${localStorage.getItem('token')}`,
                },
            },
        );
        reloadPageForUnauthorized(usersObj);
        const usersResponse = await usersObj.json();
        const users: IUser[] = [];
        for (const user of usersResponse) {
            users.push({
                employeeId: user.id,
                email: user.email,
                role: user.role,
                location: user.location,
                firstName: user.first_name,
                lastName: user.last_name,
                vpId: user.vpId,
                managerId: user.managerId,
                employeeName: `${getUserFullName(user.first_name, user.last_name)}`,
                // eslint-disable-next-line prettier/prettier
                managerName: `${
                    user.ManagerFirstName ? getUserFullName(user.ManagerFirstName, user.ManagerLastName) : '-'
                }`,
                vpName: `${user.VFirstName ? getUserFullName(user.VFirstName, user.VLastName) : '-'}`,
            });
        }

        return users;
    } catch (err) {
        console.log('err', err);
    }
    return undefined;
};

const setExecutiveReviewForUsers = async (formData: unknown): Promise<IStatusWithResponse | undefined> => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: ` Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify(formData),
        };
        const cycleResponse = await fetch(`${process.env.REACT_APP_API_HOST}/v1/`, requestOptions);
        reloadPageForUnauthorized(cycleResponse);
        return { status: getAPIStatus(cycleResponse.status), response: cycleResponse };
        // return getAPIStatus(userResponse.status);
    } catch (err) {
        console.log(err);
    }
};

const approveExecutiveReviewSelectedUsers = async (formData: unknown): Promise<IStatusWithResponse | undefined> => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: ` Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify(formData),
        };
        const approveResponse = await fetch(
            `${process.env.REACT_APP_API_HOST}/v1/users/executive-approval`,
            requestOptions,
        );
        reloadPageForUnauthorized(approveResponse);
        return { status: getAPIStatus(approveResponse.status), response: approveResponse };
        // return getAPIStatus(userResponse.status);
    } catch (err) {
        console.log(err);
    }
};

const rejectExecutiveReviewSelectedUsers = async (formData: unknown): Promise<IStatusWithResponse | undefined> => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: ` Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify(formData),
        };
        const rejectResponse = await fetch(
            `${process.env.REACT_APP_API_HOST}/v1/users/executive-rejection`,
            requestOptions,
        );
        reloadPageForUnauthorized(rejectResponse);
        return { status: getAPIStatus(rejectResponse.status), response: rejectResponse };
        // return getAPIStatus(userResponse.status);
    } catch (err) {
        console.log(err);
    }
};

export {
    getAllUser,
    getUser,
    getEmployeesOfManger,
    getReporteesGoalInfo,
    userAutoSearch,
    addUser,
    getUsersWithoutGoals,
    setCyclesForUsers,
    getExecutiveReporteesGoalInfo,
    getEmployeesOfExecutive,
    getUsersForExecutiveReview,
    setExecutiveReviewForUsers,
    approveExecutiveReviewSelectedUsers,
    rejectExecutiveReviewSelectedUsers,
};
