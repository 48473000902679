import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();
import useAutosizeTextArea from '../useAutosizeTextArea';

import './OwnersSelect.scss';
import { ISelectFilter, IGoalAction, IOwnerSelectFilter } from '../../../interfaces';
import { userAutoSearch } from '../../../API/Users';

interface Props {
    data: ISelectFilter[];
    disabled: boolean;
    indexKey: number;
    validate: boolean;
    dispatch?: React.Dispatch<IGoalAction>;
}

const OwnersSelect: React.FC<Props> = ({ data, disabled, indexKey, dispatch, validate }: Props) => {
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const promiseOptions = async (inputValue: string): Promise<ISelectFilter[]> => {
        setLoading(true);
        return new Promise(async (resolve) => {
            const users = await userAutoSearch(inputValue);
            if (users) {
                resolve(users);
                setLoading(false);
            }
        });
    };

    useEffect(() => {
        if (data.length > 0 && error) {
            setError(false);
        }
    }, [data]);

    useEffect(() => {
        if (validate && data.length == 0) {
            setError(true);
        }
    }, [validate]);

    return (
        <AsyncSelect
            name="ownerId"
            id="ownerId"
            components={animatedComponents}
            value={data}
            isLoading={isLoading}
            hideSelectedOptions={true}
            isDisabled={disabled}
            isMulti
            className={`react-select-user ${error ? 'invalid' : ''}`}
            classNamePrefix="select"
            loadOptions={promiseOptions}
            onChange={(option) => {
                dispatch &&
                    dispatch({
                        type: 'CHANGE_OWNER',
                        key: indexKey,
                        value: option as IOwnerSelectFilter[],
                    });
            }}
            menuPortalTarget={document.body}
        />
    );
};

export default OwnersSelect;
