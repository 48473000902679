import { IComments } from '../interfaces';
import { getUserShortName, getUserFullName, reloadPageForUnauthorized } from '../utils';

const getComments = async (goalId: number): Promise<IComments[] | undefined> => {
    try {
        const commentsObj = await fetch(`${process.env.REACT_APP_API_HOST}/v1/goals/${goalId}/comments`, {
            headers: {
                Authorization: ` Bearer ${localStorage.getItem('token')}`,
            },
        });
        reloadPageForUnauthorized(commentsObj);
        const commentsList = await commentsObj.json();
        const comments: IComments[] = [];
        for (const c of commentsList) {
            comments.push({
                comment: c.text.trim(),
                commentId: c.id,
                date: new Date(parseInt(c.createdAt)),
                userId: c.commentorId,
                userName: getUserFullName(c.commentor.firstName, c.commentor.lastName),
                shortName: getUserShortName(c.commentor.firstName, c.commentor.lastName),
                role: c.commentor.role,
            });
        }
        comments.sort((a, b) => (a.date > b.date ? 1 : -1));
        return comments;
    } catch (err) {
        console.log(err);
    }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const addComment = async (goalId: number, formData: unknown) => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: ` Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify(formData),
        };
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/v1/goals/${goalId}/comments`, requestOptions);
        reloadPageForUnauthorized(response);

        return response;
    } catch (err) {
        console.log(err);
    }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const updateComment = async (goalId: number, formData: unknown) => {
    try {
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: ` Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify(formData),
        };
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/v1/goals/${goalId}/comments`, requestOptions);
        reloadPageForUnauthorized(response);

        return response;
    } catch (err) {
        console.log(err);
    }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const removeComment = async (goalId: number, commentId: number) => {
    try {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: ` Bearer ${localStorage.getItem('token')}`,
            },
        };
        const response = await fetch(
            `${process.env.REACT_APP_API_HOST}/v1/goals/${goalId}/comments/${commentId}`,
            requestOptions,
        );
        reloadPageForUnauthorized(response);

        return response;
    } catch (err) {
        console.log(err);
    }
};

export { getComments, addComment, updateComment, removeComment };
