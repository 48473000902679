import React from 'react';

import { IGoalAction } from '../../../interfaces';

interface Props {
    indexKey: number;
    data: boolean;
    disabled: boolean;
    validate?: boolean;
    readonly: boolean;
    dispatch?: React.Dispatch<IGoalAction>;
}
const Confidential: React.FC<Props> = ({ indexKey, data, disabled, readonly, dispatch }: Props) => {
    return (
        <input
            className="form-control"
            name="isPrivate"
            type="checkbox"
            checked={data}
            readOnly={readonly}
            disabled={disabled}
            onChange={(e) => {
                dispatch &&
                    dispatch({
                        type: 'CHANGE_CONFIDENCE',
                        key: indexKey,
                        value: e.target.checked,
                    });
            }}
            style={{ width: '18px' }}
        />
    );
};

export default Confidential;
