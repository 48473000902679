import React, { useEffect, useState } from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { PlusCircleSvg } from '../../icons';
import 'react-circular-progressbar/dist/styles.css';
import { IStatus } from '../../interfaces';
import CONSTANTS from '../../constants';
import './CircularProgressBarNew.scss';
import { formatNumber } from '../../utils';
interface Props {
    percentage: number;
    status: IStatus;
    score: number;
}

type IStatusType = 'NOT_SCORED' | 'SCORED' | 'NOT_STARTED';

const CircularProgressBarNew: React.FC<Props> = ({ percentage, status, score }: Props) => {
    const [progressBar, setProgressBar] = useState(0);
    const updatePercentage = () => {
        setTimeout(() => {
            setProgressBar(progressBar + 1);
        }, 5);
    };

    useEffect(() => {
        if (progressBar < percentage) updatePercentage();
    }, [progressBar]);

    useEffect(() => {
        setProgressBar(percentage);
    }, [percentage, status]);

    const notStarted: IStatus[] = ['N/A', 'NEW'];

    let statusType: IStatusType = 'NOT_SCORED';
    if (notStarted.includes(status)) {
        statusType = 'NOT_STARTED';
    } else if (
        status === 'COMPLETED' ||
        status === 'SCORES_REVIEW' ||
        status === 'TARGETS_APPROVED' ||
        status === 'REVISE_REVERTED' ||
        status === 'SCORES_WITHDRAWN'
    ) {
        statusType = 'SCORED';
    }

    const trailColor = statusType == 'NOT_STARTED' || statusType == 'SCORED' ? '#FFF' : '#B7B7B7';

    return (
        <div className={`circle-progress-bar`}>
            <CircularProgressbarWithChildren
                value={progressBar}
                strokeWidth={3}
                background={true}
                minValue={0}
                backgroundPadding={7}
                styles={buildStyles({
                    rotation: 0,
                    strokeLinecap: 'butt',
                    textSize: '16px',
                    pathTransitionDuration: 1,
                    pathColor:
                        status === 'COMPLETED' ||
                        status === 'SCORES_REVIEW' ||
                        status === 'TARGETS_APPROVED' ||
                        status === 'REVISE_REVERTED' ||
                        status === 'SCORES_WITHDRAWN'
                            ? CONSTANTS.STATUS_COLORS[status]
                            : '#B7B7B7',
                    textColor: '#f88',
                    trailColor: trailColor,
                    backgroundColor: '#FFF',
                })}
            >
                <div className="text">
                    {statusType == 'NOT_STARTED' && (
                        <div className="not-started">
                            <PlusCircleSvg />
                        </div>
                    )}
                    {statusType == 'NOT_SCORED' && (
                        <div className="not-scored">
                            <h5>Not</h5>
                            <h5>Scored</h5>
                        </div>
                    )}
                    {statusType == 'SCORED' && <div>{formatNumber(score)}</div>}
                </div>
            </CircularProgressbarWithChildren>
        </div>
    );
};

export default CircularProgressBarNew;
