import React, { useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { Loader } from '../index';
interface Props {
    handleAuthentication: () => Promise<void>;
}

const CodeVerify: React.FC<Props> = ({ handleAuthentication }: Props) => {
    const { search } = useLocation();
    const PKCEAuthCodeSecondStep = async (code: string) => {
        const oidcURL = `${process.env.REACT_APP_OIDC_URL}/token `;
        const queryParams = {
            grant_type: 'authorization_code',
            redirect_uri: `${process.env.REACT_APP_HOST}${process.env.PUBLIC_URL}/oauth/callback`,
            client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
            code_verifier: localStorage.getItem('code_verifier'),
            code,
        };

        const params = qs.stringify(queryParams);

        localStorage.removeItem('code_verifier');
        return axios
            .post(oidcURL, params, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then((res) => {
                localStorage.setItem('access_token', res.data.access_token);
                localStorage.setItem('token', res.data.id_token);
                handleAuthentication();
                return res.data;
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.data);
                }
            });
    };

    const handleRequest = async () => {
        try {
            const urlParams = new URLSearchParams(search);
            const code = urlParams.get('code');
            if (code) {
                await PKCEAuthCodeSecondStep(code);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        handleRequest();
    }, []);

    return <Loader />;
};

export default CodeVerify;
