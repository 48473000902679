import React, { useEffect, useState } from 'react';

const HealthCheck: React.FC = () => {
    const [apiResponse, setApiResponse] = useState<string>(`OK`);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_HOST}/v1/health-check`, {
            headers: {
                'x-health-check': 'true',
                Authorization: `Bearer 123456789`,
            },
        })
            .then((res) => res.text())
            .then((data) => {
                setApiResponse(data);
                setIsLoading(false);
            })
            .catch(() => {
                setApiResponse(`NO`);
                setIsLoading(false);
            });
    }, []);

    if (isLoading) {
        return null;
    }

    return <>{apiResponse}</>;
};

export default HealthCheck;
