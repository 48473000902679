import papa, { ParseError } from 'papaparse';
import { getCategory } from '../API/common';
import { createGoal } from '../API/Goals';
import { userAutoSearch } from '../API/Users';
import { getGoalsList, deleteGoal } from '../API/Goals';

import CONSTANTS from '../constants';
import { IGoal, IQuarterType } from '../interfaces';
import { Workbook } from 'exceljs';
import fs from 'file-saver';

const defaultConfig = {
    skipEmptyLines: true,
    header: true,
    dynamicTyping: true,
};

interface IData {
    BINARY: boolean;
    CATEGORY: string;
    CONFIDENTIAL: boolean;
    TARGET_100: string;
    OWNER?: string;
    POINTS: number;
    TARGET: string;
    TARGET_0: string;
    LOSS: number;
    SCORE: number;
}

interface IDownloadData {
    BINARY: string;
    CATEGORY: string;
    CONFIDENTIAL: string;
    TARGET_100: string;
    OWNER?: string;
    POINTS: number;
    TARGET: string;
    TARGET_0: string;
    LOSS: number;
    SCORE: number;
    EOQ: string;
}

const requiredColumns = ['BINARY', 'CATEGORY', 'OWNER', 'CONFIDENTIAL', '100% TARGET', 'POINTS', 'TARGET', '0% TARGET'];

const parseFile = async (rawFile: File): Promise<[IData[], ParseError[]]> => {
    return new Promise<[IData[], ParseError[]]>((resolve, reject) => {
        papa.parse(rawFile, {
            ...defaultConfig,
            complete: (results) => {
                resolve([results.data as IData[], results.errors]);
            },
            error: (error: ParseError) => {
                return reject(error);
            },
            beforeFirstChunk: function (chunk) {
                const rows = chunk.split(/\r\n|\r|\n/);
                const headings = rows[0]
                    .split(',')
                    .map((c) => c.trim())
                    .join(',');
                rows[0] = headings;
                return rows.join('\r\n');
            },
        });
    });
};

type IStatus = 'SUCCESS' | 'FAILURE';

interface ErrorJSONType {
    [key: string]: number[];
}

const checkUploadedData = async (
    importedData: any,
): Promise<{ status: IStatus; message: string; rowNo?: number[] }[] | undefined> => {
    try {
        const parsedData = JSON.parse(importedData);
        const errorJson: ErrorJSONType = {
            'No data': [],
            'No category mentioned': [],
            'Some column values are missing': [],
            'Points should be Numeric': [],
            'Owner email is not correct': [],
        };
        if (parsedData.length > 0) {
            const categories = await getCategory();
            const errorsArray: { status: IStatus; message: string; rowNo?: number[] }[] = [];
            const header = parsedData[0];
            if (
                header[0] !== 'CATEGORY' ||
                header[1] !== 'TARGET' ||
                header[2] !== 'OWNER' ||
                header[3] !== 'POINTS' ||
                header[4] !== 'BINARY' ||
                header[5] !== '100% TARGET' ||
                header[6] !== '0% TARGET' ||
                header[7] !== 'CONFIDENTIAL'
            ) {
                errorsArray.push({ status: 'FAILURE', message: 'Header mismatch' });
            }
            for (let rowNo = 1; rowNo < parsedData.length; rowNo++) {
                if (categories && categories?.length > 0 && parsedData[rowNo].length > 0 && parsedData[rowNo][0]) {
                    const [category] = categories?.filter(
                        (c) =>
                            c.label &&
                            c.label.toLowerCase().trim().replaceAll(' ', '-') ==
                                parsedData[rowNo][0].toLowerCase().trim().replaceAll(' ', '-'),
                    );
                    if (!category) {
                        if (
                            !errorJson[`Category mismatch ${parsedData[rowNo][0] ? `"${parsedData[rowNo][0]}"` : ''}`]
                        ) {
                            errorJson[`Category mismatch ${parsedData[rowNo][0] ? `"${parsedData[rowNo][0]}"` : ''}`] =
                                [];
                        }
                        await errorJson[
                            `Category mismatch ${parsedData[rowNo][0] ? `"${parsedData[rowNo][0]}"` : ''}`
                        ].push(rowNo);
                    }
                }
                if (parsedData[rowNo].length === 0) {
                    errorJson['No data'].push(rowNo);
                }
                if (parsedData[rowNo][0] === null) {
                    errorJson['No category mentioned'].push(rowNo);
                }
                const innerArray = parsedData[rowNo];
                for (let j = 0; j < innerArray.length; j++) {
                    if (
                        innerArray[j] === null &&
                        innerArray.length === 8 &&
                        header[j] !== 'OWNER' &&
                        header[j] !== '100% TARGET' &&
                        header[j] !== '0% TARGET'
                    ) {
                        if (!errorJson[`No "${header[j]}" mentioned`]) {
                            errorJson[`No "${header[j]}" mentioned`] = [];
                        }
                        errorJson[`No "${header[j]}" mentioned`].push(rowNo);
                        continue;
                    }
                    if (header[j] === 'POINTS' && isNaN(innerArray[j]) && isNaN(parseFloat(innerArray[j]))) {
                        errorJson['Points should be Numeric'].push(rowNo);
                    }
                    if (
                        (header[j] === 'BINARY' || header[j] === 'CONFIDENTIAL') &&
                        !(
                            (innerArray[j] && innerArray[j].toLowerCase() === 'yes') ||
                            (innerArray[j] && innerArray[j].toLowerCase() === 'no')
                        )
                    ) {
                        if (!errorJson[`${header[j]} should be Yes/No`]) {
                            errorJson[`${header[j]} should be Yes/No`] = [];
                        }
                        errorJson[`${header[j]} should be Yes/No`].push(rowNo);
                    }
                    if (innerArray.length < 8) {
                        if (!errorJson['Some column values are missing'].includes(rowNo)) {
                            errorJson['Some column values are missing'].push(rowNo);
                        }
                        continue;
                    }
                }
            }
            const tempArray: { status: IStatus; message: string; rowNo?: number[] }[] = [];
            Object.keys(errorJson).forEach((key: string) => {
                if (errorJson[key].length > 0) {
                    tempArray.push({ status: 'FAILURE', message: key, rowNo: errorJson[key] });
                }
            });
            return tempArray;
        } else {
            return [{ status: 'FAILURE', message: 'No records Found' }];
        }
    } catch (err) {
        console.log(err);
    }
};

const useUploadCSVData = async (
    file: File,
    employeeId: number,
    goalId: number,
    importedData: any,
): Promise<{ status: IStatus; message?: string; rowNo?: number } | undefined> => {
    try {
        // const [parsedData, errors] = await parseFile(importedData);
        // if (errors.length > 0) {
        //     const [error] = errors;
        //     return { status: 'FAILURE', message: error.message };
        // }
        const parsedData = JSON.parse(importedData);
        if (parsedData.length > 0) {
            const categories = await getCategory();
            const newGoals = [];
            for (let rowNo = 1; rowNo < parsedData.length; rowNo++) {
                if (categories && categories?.length > 0) {
                    const [category] = categories?.filter(
                        (c) =>
                            c.label &&
                            c.label.toLowerCase().trim().replaceAll(' ', '-') ==
                                parsedData[rowNo][0].toLowerCase().trim().replaceAll(' ', '-'),
                    );

                    // regex delimiter for random whitespaces or commas
                    const ownersList = parsedData[rowNo][2] ? parsedData[rowNo][2].split(/[ ,]+/) : [];
                    const ownersIds = [];
                    const owners = [{ ownerId: employeeId }];
                    if (ownersList.length > 0) {
                        for (const owner of ownersList) {
                            const userSearch = await userAutoSearch(owner);
                            if (userSearch && userSearch?.length > 0) {
                                ownersIds.push({ ownerId: userSearch[0].value });
                            }
                        }
                    }

                    newGoals.push({
                        goalId: goalId,
                        categoryId: category.value,
                        target: parsedData[rowNo][1] ? parsedData[rowNo][1] : '',
                        owners: ownersIds.length > 0 ? ownersIds : owners,
                        points: parsedData[rowNo][3] ? parsedData[rowNo][3] : 0,
                        isBinary: parsedData[rowNo][4] && parsedData[rowNo][4].toLowerCase() === 'yes' ? true : false,
                        fullTarget: parsedData[rowNo][5] ? parsedData[rowNo][5] : '',
                        zeroTarget:
                            parsedData[rowNo][6] && !(parsedData[rowNo][4].toLowerCase() === 'yes')
                                ? parsedData[rowNo][6]
                                : '',
                        isPrivate: parsedData[rowNo][7] && parsedData[rowNo][7].toLowerCase() === 'yes' ? true : false,
                        loss: 0,
                        completionProof: '',
                    });
                }
            }

            //get existing items
            const existingGoalItems = await getGoalsList(goalId);
            const existingGoalItemIds = [];
            if (existingGoalItems) {
                for (const goalItem of existingGoalItems) {
                    existingGoalItemIds.push(goalItem.goalItemId);
                }
            }

            // delete existing items
            if (existingGoalItemIds.length > 0) {
                await deleteGoal(goalId, existingGoalItemIds);
            }

            // create imported goal items
            const status = await createGoal(goalId, newGoals);
            if (status && status == 'FAILURE') {
                return { status: 'FAILURE', message: CONSTANTS.MESSAGES.FAILURE };
            } else {
                return { status: 'SUCCESS', message: `Imported ${newGoals.length} Goals Successfully` };
            }
        } else {
            return { status: 'FAILURE', message: 'No records Found' };
        }
    } catch (err) {
        console.log(err);
    }
};

const useDownloadCSVData = async (
    goalData: IGoal[],
    quarter: IQuarterType,
    year: number,
    userNameSlug: string,
): Promise<void> => {
    const data: IDownloadData[] = [];
    let normalPoints = 0;
    let stretchPoints = 0;
    let finalScore = 0;
    let stretchLoss = 0;

    for (const goal of goalData) {
        let allOwners = '';
        const listOfOwners = goal.owner;
        listOfOwners.forEach((owner) => {
            if (allOwners === '') allOwners = allOwners + owner.email;
            else allOwners = allOwners + ', ' + owner.email;
        });

        if (goal.category !== 'STRETCH') {
            normalPoints += goal.points;
        } else {
            stretchPoints += goal.points;
            stretchLoss += goal.loss;
        }

        data.push({
            CATEGORY: goal.category,
            TARGET: goal.target,
            OWNER: allOwners,
            POINTS: goal.points,
            BINARY: goal.binaryTarget ? 'Yes' : 'No',
            TARGET_100: goal.fullTarget,
            TARGET_0: goal.zeroTarget,
            CONFIDENTIAL: goal.isPrivate ? 'Yes' : 'No',
            LOSS: goal.loss,
            SCORE: goal.points - goal.loss,
            EOQ: goal.completionProof ? goal.completionProof : 'N/A',
        });
    }
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(`${userNameSlug}-goals-${year}-${quarter}`);
    const header = [
        'CATEGORY',
        'TARGET',
        'OWNER',
        'POINTS',
        'BINARY',
        '100% TARGET',
        '0% TARGET',
        'CONFIDENTIAL',
        'LOSS',
        'SCORE',
        'EOQ Results',
    ];
    worksheet.addRow(header);

    let numberOfGoalsItem = 0;
    for (const cols of data) {
        const colsObj = cols as { [key: string]: any };
        const colsObjKeys = Object.keys(colsObj);
        const temp = [];
        for (const key of colsObjKeys) {
            if (key === 'SCORE') {
                finalScore = finalScore + colsObj[key];
            }
            temp.push(colsObj[key]);
        }
        worksheet.addRow(temp);
        numberOfGoalsItem++;
    }

    // add an empty row
    worksheet.addRow(['', '', '', '', '', '', '', '', '', '', '']);

    worksheet.addRow([
        '',
        '',
        'Goals Points',
        normalPoints,
        '',
        '',
        '',
        'Goals Score',
        normalPoints + stretchPoints - finalScore - stretchLoss,
        finalScore - (stretchPoints - stretchLoss),
        '',
    ]);
    worksheet.addRow([
        '',
        '',
        'Stretch Points',
        stretchPoints,
        '',
        '',
        '',
        'Stretch Score',
        stretchLoss,
        stretchPoints - stretchLoss,
        '',
    ]);
    worksheet.addRow([
        '',
        '',
        'Total Points',
        normalPoints + stretchPoints,
        '',
        '',
        '',
        'Total Score',
        normalPoints + stretchPoints - finalScore,
        finalScore,
        '',
    ]);

    worksheet.eachRow(function (row, rowNumber) {
        let currRow = JSON.stringify(row.values);
        currRow = JSON.parse(currRow);
        row.eachCell((cell, colNumber) => {
            // setting font color of stretch row
            if (currRow[1] === 'STRETCH') {
                cell.font = {
                    color: { argb: '008000' },
                };
            }

            // setting font color of LOSS column
            if (colNumber == 9 && rowNumber > 1 && cell.value && cell.value > 0) {
                cell.font = {
                    color: { argb: 'ff0000' },
                };
            }

            if (rowNumber === 1) {
                // bold
                cell.font = {
                    bold: true,
                };

                // grey background
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'd3d3d3' },
                };
            }

            // border
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
            };

            if (rowNumber <= numberOfGoalsItem) {
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'medium' },
                };
            }

            if (rowNumber === numberOfGoalsItem + 1) {
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'medium' },
                    right: { style: 'medium' },
                };
            }

            if (rowNumber === numberOfGoalsItem + 5 && colNumber !== 11) {
                cell.font = {
                    bold: true,
                };
                if (colNumber === 9) {
                    cell.font = {
                        color: { argb: 'ff0000' },
                    };
                }
            }

            if (rowNumber > numberOfGoalsItem + 1 && rowNumber < numberOfGoalsItem + 5 && colNumber === 11) {
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'medium' },
                };
            }

            if (rowNumber === numberOfGoalsItem + 5) {
                if (colNumber === 11) {
                    cell.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'medium' },
                        right: { style: 'medium' },
                    };
                } else {
                    cell.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'medium' },
                        right: { style: 'thin' },
                    };
                }
            }

            // stretch green
            if (rowNumber === numberOfGoalsItem + 4 && colNumber !== 9 && colNumber !== 11) {
                cell.font = {
                    color: { argb: '008000' },
                };
            }

            // align
            if (rowNumber !== 1) {
                cell.alignment = {
                    horizontal: 'left',
                    vertical: 'middle',
                    wrapText: true,
                };
            }
        });
        row.commit();
    });

    worksheet.columns.forEach((column, i) => {
        if (i == 0 || i == 7) {
            column.width = 13;
        } else if (i == 3 || i == 4 || i == 8 || i == 9) {
            column.width = 7;
        } else {
            column.width = 23;
        }
    });
    const fname = `${userNameSlug}-goals-${year}-${quarter}`;

    //add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, fname + '.xlsx');
    });
};

export { useUploadCSVData, useDownloadCSVData, checkUploadedData };
