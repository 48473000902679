import React from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { ICategorySummary } from '../../interfaces/DashboardInterface';
import { ChartOptions } from 'chart.js';

import './TeamCategory.scss';

interface Props {
    dataset: ICategorySummary[];
}

const TeamCategory: React.FC<Props> = ({ dataset }: Props) => {
    const data = {
        labels: ['Targets', 'Scores'],
        datasets: dataset,
    };

    const options: ChartOptions = {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            yAxes: [
                {
                    stacked: true,
                    ticks: {
                        beginAtZero: true,
                    },
                    gridLines: {
                        display: false,
                    },
                },
            ],
            xAxes: [
                {
                    stacked: true,
                    ticks: {
                        beginAtZero: true,
                    },
                    gridLines: {
                        display: false,
                    },
                },
            ],
        },
        tooltips: {
            enabled: true,
            mode: 'nearest',
            position: 'average',
            bodyAlign: 'center',
        },
        legend: {
            display: true,
            labels: {
                boxWidth: 10,
                fontSize: 10,
                usePointStyle: true,
                fontStyle: 'bold',
            },
        },
    };

    return (
        <div className="team-category">
            <HorizontalBar data={data} width={100} height={100} options={options} />
        </div>
    );
};

export default TeamCategory;
