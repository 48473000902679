import React, { useState, useEffect, Suspense } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import jwt from 'jsonwebtoken';

import './App.scss';
import { Sidebar, Header, CodeVerifier, Auth, Loader } from './components';
import PageRoutes from './Routes';
import { getUser } from './API/Users';
import { PublicRoute, PrivateRoute } from './RouteType';
import { IContextUserDetail } from './interfaces';
import { Documentation, releaseNotes, FeedbackExportPage } from './pages';
import { checkAccessForPage, convertToEpoch } from './utils';
import { useGAPageView, useSetEmployeeId } from './hooks/useGoogleAnalytics';

const initialUserState: IContextUserDetail = {
    employeeId: 0,
    email: '',
    role: 'EMPLOYEE',
    employeeName: '',
    managerName: '',
    isManager: false,
    isExecutive: false,
    hasSubordinates: false,
};

export const EmployeeContext = React.createContext<IContextUserDetail>(initialUserState);

const GoalsRoutes: React.FC = () => {
    const [toggle, setToggle] = useState(false);
    const [user, setUser] = useState<IContextUserDetail>(initialUserState);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();
    const { triggerPageView } = useGAPageView();

    useEffect(() => {
        return history.listen((location) => {
            triggerPageView(location);
        });
    }, [history]);

    useEffect(() => {
        if (isAuthenticated) useSetEmployeeId(user.employeeId);
    }, [isAuthenticated]);

    const handleAuthentication = async () => {
        const tokenStr = localStorage.getItem('token');
        if (tokenStr) {
            const token = jwt.decode(tokenStr) as { exp: number; unique_name: string };
            if (token) {
                if (typeof token.exp !== 'undefined' && token.exp < convertToEpoch(new Date()) / 1000) {
                    setIsAuthenticated(false);
                } else {
                    const userInfo = await getUser(token.unique_name);
                    if (userInfo) {
                        setUser({
                            ...userInfo,
                            employeeId: userInfo.employeeId,
                            employeeName: userInfo.employeeName,
                            role: userInfo.role,
                            email: userInfo.email,
                            managerName: userInfo.managerName,
                            isManager: userInfo.isManager,
                            isExecutive: userInfo.isExecutive,
                        });
                        setIsAuthenticated(true);
                    } else {
                        history.push(`/unauthorized`);
                    }
                }
            }
        }
        setIsLoading(false);
    };

    useEffect(() => {
        handleAuthentication();
    }, []);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div>
                    <EmployeeContext.Provider value={user}>
                        <PublicRoute
                            restricted={true}
                            component={Auth}
                            path={`/signin`}
                            exact
                            isAuthenticated={isAuthenticated}
                            handleAuthentication={handleAuthentication}
                        />
                        <PublicRoute
                            restricted={true}
                            component={CodeVerifier}
                            path={`/oauth/callback`}
                            isAuthenticated={isAuthenticated}
                            handleAuthentication={handleAuthentication}
                        />
                        {isAuthenticated && (
                            <Sidebar toggleActive={toggle} toggleHandler={(value) => setToggle(value)} />
                        )}
                        <Suspense fallback={<Loader />}>
                            <div className="main">
                                {isAuthenticated && <Header toggleHandler={(value) => setToggle(value)} />}
                                <Switch>
                                    <Route exact path={`/`} render={() => <Redirect to="/dashboard" />}></Route>
                                    {PageRoutes.map((route, index) => {
                                        //giving access to all routes when ot authenticated for redirection to login page
                                        if (
                                            checkAccessForPage(
                                                user.role,
                                                route.role,
                                                user.isManager,
                                                user.isExecutive,
                                            ) ||
                                            !isAuthenticated
                                        ) {
                                            if (route.children.length > 0) {
                                                return (
                                                    <Route
                                                        key={index}
                                                        path={route.path}
                                                        render={() => {
                                                            return (
                                                                <div>
                                                                    <PrivateRoute
                                                                        key={index}
                                                                        path={route.path}
                                                                        component={route.component}
                                                                        isAuthenticated={isAuthenticated}
                                                                        userId={user.employeeId}
                                                                        exact
                                                                    />
                                                                    {route.children.map((childRoutes) => (
                                                                        <PrivateRoute
                                                                            key={`${childRoutes.path}-${index}`}
                                                                            path={`${childRoutes.path}`}
                                                                            component={childRoutes.component}
                                                                            isAuthenticated={isAuthenticated}
                                                                            userId={user.employeeId}
                                                                            exact
                                                                        />
                                                                    ))}
                                                                </div>
                                                            );
                                                        }}
                                                    />
                                                );
                                            } else
                                                return (
                                                    <PrivateRoute
                                                        key={index}
                                                        path={route.path}
                                                        component={route.component}
                                                        isAuthenticated={isAuthenticated}
                                                        userId={user.employeeId}
                                                    />
                                                );
                                        }
                                    })}
                                    <PrivateRoute
                                        path={`/documentation`}
                                        component={Documentation}
                                        isAuthenticated={isAuthenticated}
                                        userId={user.employeeId}
                                    />
                                    <PrivateRoute
                                        path={`/releases`}
                                        component={releaseNotes}
                                        isAuthenticated={isAuthenticated}
                                        userId={user.employeeId}
                                    />
                                    <PrivateRoute
                                        path={`/aprfeedback/export/:aprFeedbackId`}
                                        component={FeedbackExportPage}
                                        isAuthenticated={isAuthenticated}
                                        userId={user.employeeId}
                                    />
                                    {/* <Route component={NotFound} /> */}
                                </Switch>
                            </div>
                        </Suspense>
                    </EmployeeContext.Provider>
                </div>
            )}
        </>
    );
};

export default GoalsRoutes;
