import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();
import './UserSelect.scss';
import { ISelectFilter } from '../../../interfaces';
import { userAutoSearch } from '../../../API/Admin';

interface Props {
    value: ISelectFilter;
    handleChange: (arg: ISelectFilter) => void;
}

const UsersSelect: React.FC<Props> = ({ value, handleChange }: Props) => {
    const [isLoading, setLoading] = useState(false);
    const promiseOptions = async (inputValue: string): Promise<ISelectFilter[]> => {
        setLoading(true);
        return new Promise(async (resolve) => {
            const users = await userAutoSearch(inputValue);
            if (users) {
                resolve(users);
                setLoading(false);
            }
        });
    };

    return (
        <AsyncSelect
            name="ownerId"
            id="ownerId"
            components={animatedComponents}
            value={value}
            isLoading={isLoading}
            hideSelectedOptions={true}
            isDisabled={false}
            className="admin-react-select-user"
            classNamePrefix="select"
            loadOptions={promiseOptions}
            onChange={(option) => {
                handleChange(option as ISelectFilter);
            }}
            menuPortalTarget={document.body}
        />
    );
};

export default UsersSelect;
