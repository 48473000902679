import React from 'react';
import { Trash, PlusCircleDotted, Files } from 'react-bootstrap-icons';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

import './GoalItem.scss';
import { IGoal, IGoalAction, ISelectFilter } from '../../interfaces';
import { IGoalSaveType } from '../../interfaces/Common';
import { Draggable } from 'react-beautiful-dnd';
import {
    CategorySelect,
    OwnersSelect,
    Target,
    Points,
    FullTarget,
    ZeroTarget,
    Confidential,
    BinaryTarget,
    Loss,
    EoqResults,
} from '../../components/InputFields';
import { formatNumber } from '../../utils';

interface Props {
    goal: IGoal;
    index: number;
    readOnly: boolean;
    goalsListDispatch?: React.Dispatch<IGoalAction>;
    categories: ISelectFilter[];
    goalType: IGoalSaveType;
    defaultGoal?: IGoal;
    validate: boolean;
}

const GoalItem: React.FC<Props> = ({
    goal,
    index,
    readOnly,
    goalsListDispatch,
    categories,
    goalType,
    defaultGoal,
    validate,
}: Props) => {
    const Index = index.toString();
    return (
        <Draggable draggableId={Index} index={index} isDragDisabled={readOnly}>
            {(provided) => (
                <tr
                    className={`goal-item ${index % 2 == 0 ? 'even' : ''}`}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                >
                    <td {...provided.dragHandleProps} className="index">
                        <p>{index + 1}</p>
                    </td>
                    <td {...provided.dragHandleProps} className="category">
                        <CategorySelect
                            categories={categories}
                            data={{ value: goal.categoryId, label: goal.category }}
                            disabled={(readOnly || goalType) !== 'GOAL' ? true : false}
                            validate={true}
                            indexKey={index}
                            dispatch={goalsListDispatch}
                        />
                    </td>
                    <td {...provided.dragHandleProps} className="target">
                        <Target
                            data={goal.target}
                            disabled={goalType !== 'GOAL' ? true : false}
                            indexKey={index}
                            readonly={readOnly}
                            validate={validate}
                            dispatch={goalsListDispatch}
                        />
                    </td>
                    <td {...provided.dragHandleProps} className="ownerId">
                        <OwnersSelect
                            data={goal.owner}
                            disabled={(readOnly || goalType) !== 'GOAL' ? true : false}
                            indexKey={index}
                            dispatch={goalsListDispatch}
                            validate={validate}
                        />
                    </td>
                    <td {...provided.dragHandleProps} className="points">
                        <Points
                            data={goal.points}
                            disabled={goalType !== 'GOAL' ? true : false}
                            readonly={readOnly}
                            indexKey={index}
                            validate={validate}
                            dispatch={goalsListDispatch}
                        />
                    </td>
                    <td>
                        <BinaryTarget
                            data={goal.binaryTarget}
                            disabled={goalType !== 'GOAL' ? true : false}
                            indexKey={index}
                            readonly={readOnly}
                            dispatch={goalsListDispatch}
                        />
                    </td>
                    <td {...provided.dragHandleProps} className="full-target">
                        <FullTarget
                            data={goal.fullTarget}
                            readonly={readOnly}
                            disabled={goalType !== 'GOAL' ? true : false}
                            indexKey={index}
                            validate={validate}
                            dispatch={goalsListDispatch}
                        />
                    </td>
                    <td {...provided.dragHandleProps} className="zero-target">
                        <ZeroTarget
                            data={goal.zeroTarget}
                            disabled={goalType !== 'GOAL' ? true : false}
                            indexKey={index}
                            readonly={readOnly}
                            validate={validate}
                            dispatch={goalsListDispatch}
                            isBinary={goal.binaryTarget}
                        />
                    </td>
                    <td className="confidential">
                        <Confidential
                            data={goal.isPrivate}
                            disabled={goalType !== 'GOAL' ? true : false}
                            readonly={readOnly}
                            indexKey={index}
                            dispatch={goalsListDispatch}
                        />
                    </td>
                    {goalType !== 'GOAL' && (
                        <>
                            <td {...provided.dragHandleProps} className="loss">
                                <Loss
                                    data={goal.loss}
                                    points={goal.points}
                                    disabled={readOnly || goalType == 'N/A'}
                                    indexKey={index}
                                    isBinary={goal.binaryTarget}
                                    readonly={readOnly}
                                    validate={goalType !== 'SCORE' && validate}
                                    dispatch={goalsListDispatch}
                                />
                            </td>
                            <td {...provided.dragHandleProps} className="score">
                                {formatNumber(goal.points - goal.loss)}
                            </td>
                            <td {...provided.dragHandleProps} className="completion-proof">
                                <EoqResults
                                    data={goal.completionProof}
                                    disabled={readOnly || goalType == 'N/A'}
                                    indexKey={index}
                                    readonly={readOnly}
                                    validate={goalType === 'SCORE' && validate}
                                    dispatch={goalsListDispatch}
                                />
                            </td>
                        </>
                    )}
                    {!readOnly && goalType === 'GOAL' && (
                        <>
                            <td className="action-buttons">
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Add</Tooltip>}>
                                    <PlusCircleDotted
                                        onClick={() =>
                                            goalsListDispatch &&
                                            defaultGoal &&
                                            goalsListDispatch({ type: 'INSERT', value: defaultGoal })
                                        }
                                        style={{ fontSize: '22px' }}
                                    />
                                </OverlayTrigger>
                            </td>
                            <td className="action-buttons">
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Copy</Tooltip>}>
                                    <Files
                                        onClick={() =>
                                            goalsListDispatch &&
                                            defaultGoal &&
                                            goalsListDispatch({ type: 'COPY_ROW', value: { ...goal, goalItemId: 0 } })
                                        }
                                        style={{ fontSize: '20px' }}
                                    />
                                </OverlayTrigger>
                            </td>
                            <td className="action-buttons">
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete</Tooltip>}>
                                    <Trash
                                        onClick={() => {
                                            goalsListDispatch && goalsListDispatch({ type: 'DELETE', key: index });
                                        }}
                                        style={{ fontSize: '20px' }}
                                    />
                                </OverlayTrigger>
                            </td>
                        </>
                    )}
                </tr>
            )}
        </Draggable>
    );
};

export default GoalItem;
