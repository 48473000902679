import React from 'react';
import Select from 'react-select';

import './CategorySelect.scss';
import { ISelectFilter, IGoalAction } from '../../../interfaces';
interface Props {
    categories: ISelectFilter[];
    data: ISelectFilter;
    indexKey: number;
    disabled: boolean;
    validate: boolean;
    dispatch?: React.Dispatch<IGoalAction>;
}

const CategorySelect: React.FC<Props> = ({ categories, data, indexKey, disabled, dispatch }: Props) => {
    return (
        <Select
            name="category"
            id="category-select"
            options={categories}
            value={data}
            className="react-select-category"
            classNamePrefix="select"
            isDisabled={disabled}
            onChange={(option) => {
                if (option) {
                    dispatch &&
                        dispatch({
                            type: 'CHANGE_CATEGORY_ID',
                            key: indexKey,
                            value: option,
                        });
                }
            }}
            menuPortalTarget={document.body}
        />
    );
};

export default CategorySelect;
