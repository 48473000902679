import { IStatus, IGoalSaveType, APIStatus, ISelectFilter, IRole } from './interfaces/Common';
import dateFormat from 'dateformat';
import { IQuarterType } from './interfaces';
import CONSTANTS from './constants';

const formatDate = (dateObj: Date, format = 'dS mmm, yyyy'): string => {
    if (Object.prototype.toString.call(dateObj) === '[object Date]') {
        if (isNaN(dateObj.getTime())) {
            return 'N/A';
        } else {
            return dateFormat(dateObj, format);
        }
    } else {
        return 'N/A';
    }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getPercentage = (points: number, score: number): number => {
    return score > 0 ? Math.round((score * 100) / points) : 0;
};

const getYear = (date: Date = new Date()): number => {
    return date.getFullYear();
};

const getQuarterForDropdown = (): IQuarterType[] => {
    return ['Q1', 'Q2', 'Q3', 'Q4', 'ANNUAL'];
};

const getRoleForDropdown = (): IRole[] => {
    return ['EMPLOYEE', 'HR'];
};

export interface ISelectString {
    value: IQuarterType;
    label: IQuarterType;
}

const getQuarterForDropdownNew = (): ISelectString[] => {
    return [
        {
            label: 'Q1',
            value: 'Q1',
        },
        {
            label: 'Q2',
            value: 'Q2',
        },
        {
            label: 'Q3',
            value: 'Q3',
        },
        {
            label: 'Q4',
            value: 'Q4',
        },
        {
            label: 'ANNUAL',
            value: 'ANNUAL',
        },
    ];
};

const getQuarter = (date: Date = new Date()): 'Q1' | 'Q2' | 'Q3' | 'Q4' => {
    const quarter = Math.floor(date.getMonth() / 3) + 1;
    switch (quarter) {
        case 1:
            return 'Q1';
        case 2:
            return 'Q2';
        case 3:
            return 'Q3';
        case 4:
            return 'Q4';
        default:
            return 'Q4';
    }
};

const getTypeOfGoal = (quarter: string): string => {
    return quarter == 'ANNUAL' ? 'ANNUALLY' : 'QUARTERLY';
};

const getYearsForDropdown = (date = new Date()): number[] => {
    let year = date.getFullYear();
    const yearArray = [];
    let i = 0;
    year = year + 1;
    do {
        yearArray.push(year - i);
        i++;
    } while (i < 5);

    return yearArray;
};

const getYearsForDropdownNew = (date = new Date()): ISelectFilter[] => {
    let year = date.getFullYear();
    const yearArray: ISelectFilter[] = [];
    year = year + 1;
    let i = 0;
    do {
        yearArray.push({ value: year - i, label: (year - i).toString() });
        i++;
    } while (i < 5);

    return yearArray;
};

const getYearsForFeedback = (): ISelectFilter[] => {
    const startYear = 2023; // Show feedback forms from this year
    const todaysDate = new Date();
    const currentYear = todaysDate.getFullYear();
    const cutoffDate = new Date(`12/01/${currentYear}`); // Show feedback form of current year only after 01-Dec date

    const yearArray: ISelectFilter[] = [];

    // Show all feedback forms from start year till date
    for (let year = currentYear; year >= startYear; year--) {
        if ((year === currentYear && todaysDate > cutoffDate) || year < currentYear) {
            yearArray.push({ value: year, label: year.toString() });
        }
    }
    return yearArray;
};

const getGoalsSaveType = (
    status: IStatus,
): { saveType: IGoalSaveType; managerEditable: boolean; employeeEditable: boolean; executiveEditable: boolean } => {
    const targetsSetting: IStatus[] = [
        'TARGETS_DRAFT',
        'TARGETS_REJECTED',
        'TARGETS_REVIEW',
        'TARGETS_REVISION',
        'TARGETS_WITHDRAWN',
        'TARGETS_EXECUTIVE_REVIEW',
    ];
    const scoresSetting: IStatus[] = [
        'SCORES_REVIEW',
        'TARGETS_APPROVED',
        'SCORES_REJECTED',
        'SCORES_WITHDRAWN',
        'REVISE_REVERTED',
        'SCORES_EXECUTIVE_REVIEW',
    ];
    const reviewList: IStatus[] = ['SCORES_REVIEW', 'TARGETS_REVIEW'];
    const completedList: IStatus[] = ['COMPLETED'];
    let saveType = 'N/A' as IGoalSaveType;
    const managerEditable = completedList.includes(status) ? false : reviewList.includes(status) ? true : false;
    const executiveEditable = completedList.includes(status)
        ? false
        : ['TARGETS_EXECUTIVE_REVIEW', 'SCORES_EXECUTIVE_REVIEW'].includes(status)
        ? true
        : false;
    const employeeEditable = completedList.includes(status)
        ? false
        : reviewList.includes(status)
        ? false
        : ['TARGETS_EXECUTIVE_REVIEW', 'SCORES_EXECUTIVE_REVIEW'].includes(status)
        ? false
        : true;
    if (targetsSetting.includes(status)) {
        saveType = 'GOAL' as IGoalSaveType;
    } else if (scoresSetting.includes(status)) {
        saveType = 'SCORE' as IGoalSaveType;
    }

    return { saveType, managerEditable, employeeEditable, executiveEditable };
};

const getUserFullName = (firstName: string, lastName: string): string => {
    return `${firstName} ${lastName}`;
};

const getUserShortName = (firstName: string, lastName: string): string => {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`;
};

const convertToEpoch = (date: Date): number => {
    return Math.floor(date.getTime());
};

const versioningStyle = (version: number): string => {
    return `Version ${version}`;
};

const getAPIStatus = (responseCode: number): APIStatus => {
    if (responseCode == 200) {
        return 'SUCCESS';
    } else if (responseCode == 401) {
        return 'UNAUTHORIZED';
    } else {
        return 'FAILURE';
    }
};

const getPreviousQuarter = (quarter: IQuarterType): IQuarterType => {
    switch (quarter) {
        case 'Q1':
            return 'Q4';
        case 'Q2':
            return 'Q1';
        case 'Q3':
            return 'Q2';
        case 'Q4':
            return 'Q3';
        default:
            return 'ANNUAL';
    }
};

const validateEmail = (email: string): boolean => {
    const regexp =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(email);
};
const getDifferenceBetweenTwoDates = (firstDate: Date, secondDate = new Date()): number => {
    const differenceInTime = secondDate.getTime() - firstDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    return differenceInDays;
};

const checkAccessForPage = (
    employeeRole: IRole,
    pageRole: IRole[],
    employeeIsManager: boolean,
    employeeIsExecutive: boolean,
): boolean => {
    if (employeeIsManager && pageRole.includes('MANAGER')) {
        return true;
    } else if (pageRole.includes(employeeRole)) {
        return true;
    } else if (employeeIsExecutive && pageRole.includes('EXECUTIVE')) {
        return true;
    } else {
        return false;
    }
};

const formatNumber = (number: number): number => {
    return Math.round(number * 1000) / 1000;
};

const getNextDate = (date: Date): Date => {
    const tomorrow = new Date(date);
    tomorrow.setDate(tomorrow.getDate() + 1);

    return tomorrow;
};

const reloadPageForUnauthorized = (res: Response): void => {
    if (res.status == 401) {
        storUrlPathInLocalStorage();
        window.location.reload();
    }
};

const storUrlPathInLocalStorage = (): void => {
    let pathname = window.location.pathname;
    pathname = pathname.replace(`/app`, '');
    localStorage.setItem('path', pathname + window.location.search);
};

const convertToPST9AM = (date: Date): Date => {
    const UTC9AM = 16;
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), UTC9AM));
};

const TEXT_ARE_LINE_HEIGHT = 22;
const DIFFERENCE_TO_TEXTAREA = 10;
const numberRowsInTextarea = (scrollHeight: number): number => {
    const height = scrollHeight - DIFFERENCE_TO_TEXTAREA;
    const count = Math.round(height / TEXT_ARE_LINE_HEIGHT);
    return count > CONSTANTS.MAX_LINES_IN_TEXTAREA ? CONSTANTS.MAX_LINES_IN_TEXTAREA : count;
};

const getUserNameSlug = (email: string): string => {
    return email.split('@')[0];
};
export {
    formatDate,
    getPercentage,
    getYear,
    getQuarter,
    getTypeOfGoal,
    getYearsForDropdown,
    getYearsForFeedback,
    getGoalsSaveType,
    getQuarterForDropdown,
    getUserFullName,
    getUserShortName,
    convertToEpoch,
    versioningStyle,
    getAPIStatus,
    getYearsForDropdownNew,
    getQuarterForDropdownNew,
    getPreviousQuarter,
    getRoleForDropdown,
    validateEmail,
    getDifferenceBetweenTwoDates,
    checkAccessForPage,
    formatNumber,
    getNextDate,
    reloadPageForUnauthorized,
    storUrlPathInLocalStorage,
    convertToPST9AM,
    numberRowsInTextarea,
    getUserNameSlug,
};
