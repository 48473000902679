import React, { useContext, useState } from 'react';
import { PersonCircle, QuestionCircle } from 'react-bootstrap-icons';
import { useHistory } from 'react-router-dom';

import './Header.scss';
import { EmployeeContext } from '../../GoalsRoutes';
import { LogoSvg } from '../../icons';

import LogOutModal from './LogOut';
import { NavDropdown } from 'react-bootstrap';

interface Props {
    toggleHandler: (value: boolean) => void;
}

const Header: React.FC<Props> = ({ toggleHandler }: Props) => {
    const employee = useContext(EmployeeContext);
    const history = useHistory();

    const [showAddPopup, setShowAddPopup] = useState(false);

    const handleLogout = () => {
        localStorage.clear();
        window.location.assign(`${process.env.REACT_APP_LOG_OUT_REDIRECT}`);
    };

    return (
        <div className="header">
            <div className="header-logo">
                <div className="toggle" onClick={() => toggleHandler(true)}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <LogoSvg />
            </div>
            <span onClick={() => history.push('/documentation')}>
                <QuestionCircle style={{ color: '#5f6062', fontSize: '24px' }} />
            </span>
            <span className="user-pic">
                <PersonCircle style={{ fontSize: '24px' }} onClick={() => setShowAddPopup(true)} />
            </span>
            <span className="user-name">
                <NavDropdown title={employee.employeeName} id="collapsible-nav-dropdown">
                    <NavDropdown.Item onClick={() => setShowAddPopup(true)}>Logout</NavDropdown.Item>
                </NavDropdown>
            </span>
            <LogOutModal
                onHide={() => setShowAddPopup(false)}
                show={showAddPopup}
                handleAlert={handleLogout}
            ></LogOutModal>
        </div>
    );
};

export default Header;
