import ReactGA from 'react-ga';
import { useLocation } from 'react-router';

const useInitializeGA = (): void => {
    ReactGA.initialize(`${process.env.REACT_APP_GA_TRACKING_ID}`, {
        debug: false,
    });
};

const useSetEmployeeId = (employeeId: number): void => {
    ReactGA.set({ userId: employeeId });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useGAPageView = (): { triggerPageView: (loc?: any) => void } => {
    const location = useLocation();
    const triggerPageView = (loc = location) => {
        ReactGA.pageview(`${loc.pathname}${loc.search}`);
    };

    return { triggerPageView };
};

type IEvents = 'USER_IMPORTED_GOALS' | 'USER_DOWNLOADED_GOALS' | 'GOALS_COPY';

type IEventsType = {
    [key in IEvents]: {
        category: string;
        action: string;
    };
};

const EVENTS_TYPE: IEventsType = {
    USER_IMPORTED_GOALS: {
        category: 'Goals Upload',
        action: 'User Uploaded the goals',
    },
    USER_DOWNLOADED_GOALS: {
        category: 'Goals Export',
        action: 'User Exported the goals',
    },
    GOALS_COPY: {
        category: 'Goals Copy',
        action: 'User copied goals from the previous quarter',
    },
};

const useGAEvents = (): { triggerEvent: (type: IEvents) => void } => {
    const triggerEvent = (type: IEvents): void => {
        ReactGA.event({
            category: EVENTS_TYPE[type].category,
            action: EVENTS_TYPE[type].action,
        });
    };

    return { triggerEvent };
};

export { useInitializeGA, useGAPageView, useGAEvents, useSetEmployeeId };
