import React from 'react';
import { formatNumber } from '../../utils';

import './GoalScoring.scss';

interface Props {
    count: number;
    point: number;
    score: number;
    currStatus: string;
}

const GoalScoring: React.FC<Props> = ({ count, point, score, currStatus }: Props) => {
    return (
        <div className="goal-scoring">
            <h4>
                Total # of goals <span>{formatNumber(count)}</span>
            </h4>
            <h4>
                Total points <span>{formatNumber(point)}</span>
            </h4>
            {(currStatus === 'TARGETS_APPROVED' ||
                currStatus === 'SCORES_WITHDRAWN' ||
                currStatus === 'REVISE_REVERTED' ||
                currStatus === 'SCORES_REVIEW' ||
                currStatus === 'SCORES_REJECTED' ||
                currStatus === 'COMPLETED') && (
                <h4>
                    Total score <span>{formatNumber(score)}</span>
                </h4>
            )}
        </div>
    );
};

export default GoalScoring;
