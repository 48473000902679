import React from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
import CONSTANTS from '../../constants';
import './Loader.scss';

const Loader: React.FC = () => {
    return (
        <div className="loader">
            <PuffLoader color={CONSTANTS.COLORS.PRIMARY_COLOR} />
        </div>
    );
};

export default Loader;
