import React, { useContext, useState } from 'react';
import { IAlert, IComments } from '../../interfaces';

import './Comment.scss';
import { EmployeeContext } from '../../GoalsRoutes';
import { formatDate } from '../../utils';
import { addComment, updateComment, removeComment } from '../../API/Comments';
import { Alert } from 'react-bootstrap';
import Modal from 'react-modal';
import { InfoCircleFill } from 'react-bootstrap-icons';
import CONSTANTS from '../../constants';

interface Props {
    comment: IComments;
    goalId: number;
    updateCommentsList: () => Promise<void>;
}
const Comment: React.FC<Props> = ({ comment, goalId, updateCommentsList }: Props) => {
    const employee = useContext(EmployeeContext);
    const [isEditable, setIsEditable] = useState(false);
    const [commentText, setCommentText] = useState(comment.comment);
    const [alert, setAlert] = useState<IAlert>({ show: false, text: '', variant: '', hide: false });

    const handleEdit = async () => {
        setIsEditable(true);
    };

    const handleDelete = async (comment: IComments) => {
        await removeComment(goalId, comment.commentId);
        updateCommentsList();
    };

    function validateComment(comment: string) {
        const regexForCheck = /^[a-zA-Z0-9\s.,!?'"-]*$/;
        return regexForCheck.test(comment);
    }

    const createComments = async () => {
        if (!validateComment(commentText)) {
            setAlert({ show: true, text: CONSTANTS.MESSAGES.INVALID_COMMENT, variant: 'warning', hide: true });
            return;
        }
        if (comment.commentId) {
            const fromData = {
                text: commentText,
                commentorId: employee.employeeId,
                goalCommentId: goalId,
                id: comment.commentId,
            };
            await updateComment(goalId, fromData);
        } else {
            const fromData = {
                text: commentText,
                commentorId: employee.employeeId,
                goalCommentId: goalId,
            };
            await addComment(goalId, fromData);
        }
        setIsEditable(false);
        updateCommentsList();
    };

    return (
        <div key={comment.commentId} className="comment-item">
            <div className={`user-icon`}>
                <div className={`${comment.role == 'EMPLOYEE' ? 'em' : 'ma'}`}>
                    <span>{comment.shortName}</span>
                </div>
            </div>
            <div className="details">
                <div className="heading">
                    <p>{comment.userName}</p>
                    <span>{formatDate(comment.date, 'dS mmm, yyyy hh:mm')}</span>
                </div>
                {!isEditable ? (
                    <>
                        <div className="comment">
                            <p>{comment.comment}</p>
                        </div>
                        {comment.userId == employee.employeeId && (
                            <div className="buttons">
                                <button className="btn btn-link" onClick={() => handleEdit()}>
                                    Edit
                                </button>
                                <button className="btn btn-link" onClick={() => handleDelete(comment)}>
                                    Delete
                                </button>
                            </div>
                        )}
                    </>
                ) : (
                    <div className="add-comment edit">
                        <textarea
                            name="comment"
                            id=""
                            rows={1}
                            placeholder="Add a comment..."
                            value={commentText}
                            onChange={(e) => {
                                setCommentText(e.target.value);
                            }}
                        ></textarea>

                        <div className="buttons">
                            <button
                                className="btn btn-outline-secondary"
                                onClick={() => {
                                    setCommentText(comment.comment);
                                    setIsEditable(false);
                                }}
                            >
                                Cancel
                            </button>
                            <button className="btn btn-secondary" onClick={() => createComments()}>
                                Save
                            </button>
                        </div>
                    </div>
                )}
            </div>
            <Modal
                isOpen={alert.show}
                className={'custom-alert'}
                onRequestClose={() => setAlert({ show: false, text: '', variant: '', hide: false })}
                shouldCloseOnOverlayClick={true}
            >
                <Alert
                    show={alert.show}
                    variant={alert.variant}
                    onClose={() => setAlert({ show: false, text: '', variant: '', hide: false })}
                    dismissible
                    style={{
                        top: '1rem',
                    }}
                >
                    {alert.variant == 'warning' && (
                        <InfoCircleFill style={{ marginRight: '10px', fontSize: '24px', fontWeight: 'bold' }} />
                    )}
                    {alert.text}
                </Alert>
            </Modal>
        </div>
    );
};

export default Comment;
