import { ISelectFilter } from '../interfaces';
import { reloadPageForUnauthorized } from '../utils';

const getCategory = async (): Promise<ISelectFilter[] | undefined> => {
    try {
        const categoryObj = await fetch(`${process.env.REACT_APP_API_HOST}/v1/category`, {
            headers: {
                Authorization: ` Bearer ${localStorage.getItem('token')}`,
            },
        });
        reloadPageForUnauthorized(categoryObj);
        const categoryList = await categoryObj.json();
        const categories: ISelectFilter[] = [];
        for (const category of categoryList) {
            categories.push({
                value: category.id,
                label: category.name,
            });
        }

        return categories;
    } catch (err) {
        console.log(err);
    }
};

export { getCategory };
