import React from 'react';

import './Unauthorized.scss';

const Unauthorized: React.FC = () => {
    return (
        <div className="unauthorized">
            <div className="circle">
                <h1>401</h1>
            </div>
            <h3>Unauthorized</h3>
            <p>Please contact Admin</p>
        </div>
    );
};

export default Unauthorized;
