import React, { useState, useEffect } from 'react';
import { Download } from 'react-bootstrap-icons';
import './GoalListInfo.scss';
import { formatDate, formatNumber, getUserNameSlug, versioningStyle } from '../../utils';
import { ImportGoals } from '../';
import { IGoal, IGoalsInfo } from '../../interfaces';
import { useDownloadCSVData } from '../../hooks/goalsFileHandler';
import ClipLoader from 'react-spinners/ClipLoader';
import CONSTANTS from '../../constants';
import { useGAEvents } from '../../hooks/useGoogleAnalytics';
import { logAction } from '../../API/Goals';
import { Alert } from 'react-bootstrap';
interface Props {
    version: number;
    submissionDate: Date | undefined;
    type: 'MANAGER' | 'EMPLOYEE';
    refreshGoals: () => Promise<void>;
    goalInfo: IGoalsInfo;
    goalData?: IGoal[];
    point?: number;
    score?: number;
    currStatus?: string;
}

const GoalListInfo: React.FC<Props> = ({
    version,
    submissionDate,
    type,
    goalInfo,
    goalData,
    refreshGoals,
    point,
    score,
    currStatus,
}: Props) => {
    const [show, setShow] = useState(false);
    const [stretchPoints, setStretchPoints] = useState(0);
    const [stretchLoss, setStretchLoss] = useState(0);
    const [downloading, setDownloading] = useState(false);
    const { triggerEvent } = useGAEvents();
    const uiActionData = {
        employeeId: goalInfo.employeeId,
        year: goalInfo.year,
        quarter: goalInfo.activeQuarter,
        action: '',
        response: '',
        logType: 'UI Action',
    };
    useEffect(() => {
        if (goalData) {
            const stretchGoalsArray = goalData.filter((goal) => goal.category === 'STRETCH');
            if (stretchGoalsArray.length > 0) {
                const sum = stretchGoalsArray.reduce((accumulator, object) => {
                    return accumulator + Number(object.points);
                }, 0);
                const sumLoss = stretchGoalsArray.reduce((accumulator, object) => {
                    return accumulator + Number(object.loss);
                }, 0);
                setStretchPoints(sum);
                setStretchLoss(sumLoss);
            } else {
                setStretchPoints(0);
                setStretchLoss(0);
            }
        }
    }, [goalData]);
    const handleShow = (value: boolean) => {
        setShow(value);
    };

    const handleRefresh = (value: boolean) => {
        refreshGoals();
        setShow(value);
    };
    const handleCSVDownload = () => {
        uiActionData.action = 'Download Goals Clicked';
        uiActionData.response = 'Download Failed';
        triggerEvent('USER_DOWNLOADED_GOALS');
        if (goalData) {
            setDownloading(true);
            useDownloadCSVData(
                goalData,
                goalInfo.activeQuarter,
                goalInfo.year,
                getUserNameSlug(goalInfo.employeeEmail),
            );
            setDownloading(false);
            uiActionData.response = 'Downloaded Successfully';
        }
        logAction(goalInfo.goalId, uiActionData);
    };
    return (
        <div className="goal-list-info">
            <div className="info">
                {version ? <h3 className="version">{versioningStyle(version)}</h3> : null}
                {submissionDate ? (
                    <h3 className="submission-date">
                        Submission last date : <span>{formatDate(submissionDate)}</span>
                    </h3>
                ) : null}
            </div>
            <div className="points">
                {currStatus === 'TARGETS_APPROVED' ||
                currStatus === 'SCORES_WITHDRAWN' ||
                currStatus === 'REVISE_REVERTED' ||
                currStatus === 'SCORES_REVIEW' ||
                currStatus === 'SCORES_REJECTED' ||
                currStatus === 'COMPLETED' ? (
                    <Alert variant="success">
                        Score :{'  '}&nbsp;&nbsp;
                        <span className="point-badge">{`${
                            score && formatNumber(score - (stretchPoints - stretchLoss))
                        } + ${formatNumber(stretchPoints - stretchLoss)} = ${score && formatNumber(score)}`}</span>
                    </Alert>
                ) : (
                    <Alert variant="success">
                        Points :{'  '}&nbsp;&nbsp;
                        <span className="point-badge">{`${
                            point ? formatNumber(point - stretchPoints) : 0
                        } + ${formatNumber(stretchPoints)} = ${point && formatNumber(point)}`}</span>
                    </Alert>
                )}
            </div>
            {type == 'EMPLOYEE' && (
                <div className="import-goals">
                    {goalInfo.status === 'TARGETS_DRAFT' ||
                    goalInfo.status === 'TARGETS_REJECTED' ||
                    goalInfo.status === 'TARGETS_WITHDRAWN' ||
                    goalInfo.status === 'TARGETS_REVISION' ? (
                        <>
                            <Download size="16" onClick={() => handleShow(true)} />
                            <h3 onClick={() => handleShow(true)}>Import Goals</h3>
                            <ImportGoals show={show} handleShow={handleRefresh} goalInfo={goalInfo} />
                        </>
                    ) : (
                        <>
                            {downloading ? (
                                <ClipLoader color={CONSTANTS.COLORS.PRIMARY_COLOR} size="26" />
                            ) : (
                                <Download size="16" onClick={() => handleCSVDownload()} />
                            )}
                            <h3 onClick={() => handleCSVDownload()}>Download Goals</h3>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default GoalListInfo;
