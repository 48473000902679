import React from 'react';

import { IGoal, IGoalAction, ISelectFilter, IStatus } from '../../interfaces';
import { IGoalSaveType } from '../../interfaces/Common';
import {
    CategorySelect,
    OwnersSelect,
    Target,
    Points,
    FullTarget,
    ZeroTarget,
    BinaryTarget,
    Loss,
    EoqResults,
} from '../../components/InputFields';
import { formatNumber } from '../../utils';

interface Props {
    goal: IGoal;
    index: number;
    readOnly: boolean;
    goalsListDispatch?: React.Dispatch<IGoalAction>;
    categories: ISelectFilter[];
    goalType: IGoalSaveType;
    defaultGoal?: IGoal;
    isValid?: boolean;
    status: IStatus;
}

const SearchGoalItem: React.FC<Props> = ({
    goal,
    index,
    readOnly,
    goalsListDispatch,
    categories,
    goalType,
    isValid,
    status,
}: Props) => {
    return (
        <tr className="goal-item">
            <td className="index">
                <p>{index + 1}</p>
            </td>
            <td className="category">
                <CategorySelect
                    categories={categories}
                    data={{ value: goal.categoryId, label: goal.category }}
                    disabled={(readOnly || goalType) !== 'GOAL' ? true : false}
                    validate={true}
                    indexKey={index}
                    dispatch={goalsListDispatch}
                />
            </td>
            <td className="target">
                <Target
                    data={goal.target}
                    disabled={goalType !== 'GOAL' ? true : false}
                    indexKey={index}
                    readonly={readOnly}
                    validate={isValid ? isValid : false}
                    dispatch={goalsListDispatch}
                />
            </td>
            <td className="ownerId">
                <OwnersSelect
                    data={goal.owner}
                    disabled={(readOnly || goalType) !== 'GOAL' ? true : false}
                    indexKey={index}
                    dispatch={goalsListDispatch}
                    validate={false}
                />
            </td>
            <td className={`points`}>
                <Points
                    data={goal.points}
                    disabled={goalType !== 'GOAL' ? true : false}
                    readonly={readOnly}
                    indexKey={index}
                    validate={false}
                    dispatch={goalsListDispatch}
                />
            </td>
            <td>
                <BinaryTarget
                    data={goal.binaryTarget}
                    disabled={goalType !== 'GOAL' ? true : false}
                    indexKey={index}
                    readonly={readOnly}
                    dispatch={goalsListDispatch}
                />
            </td>
            <td className="full-target">
                <FullTarget
                    data={goal.fullTarget}
                    readonly={readOnly}
                    disabled={goalType !== 'GOAL' ? true : false}
                    indexKey={index}
                    validate={false}
                    dispatch={goalsListDispatch}
                />
            </td>
            <td className="zero-target">
                <ZeroTarget
                    data={goal.zeroTarget}
                    disabled={goalType !== 'GOAL' ? true : false}
                    indexKey={index}
                    readonly={readOnly}
                    validate={false}
                    dispatch={goalsListDispatch}
                    isBinary={goal.binaryTarget}
                />
            </td>
            {status === 'COMPLETED' && (
                <>
                    {/* <td className="loss">
                        <Loss
                            data={goal.loss}
                            points={goal.points}
                            disabled={true}
                            indexKey={index}
                            isBinary={goal.binaryTarget}
                            readonly={readOnly}
                            validate={false}
                            dispatch={goalsListDispatch}
                        />
                    </td>
                    <td className="score">{formatNumber(goal.points - goal.loss)}</td>
                    <td className="completion-proof">
                        <EoqResults
                            data={goal.completionProof}
                            disabled={true}
                            indexKey={index}
                            readonly={readOnly}
                            validate={false}
                            dispatch={goalsListDispatch}
                        />
                    </td> */}
                </>
            )}
        </tr>
    );
};

export default SearchGoalItem;
