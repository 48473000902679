import React from 'react';

import './HorizontalCycleStatus.scss';
import { CircularProgressBarNew } from '../../components';
import { getPercentage, formatDate, getGoalsSaveType } from '../../utils';
import { IStatus, IQuarterType } from '../../interfaces';
import CONSTANTS from '../../constants';
interface Props {
    name: IQuarterType;
    points: number;
    score: number;
    status: IStatus;
    date: Date;
    isActive: boolean;
    goalId: number;
    approver: string;
    handleCycleStatus?: (quarter: IQuarterType, status: IStatus, goalId: number) => void;
}

const HorizontalCycleStatus: React.FC<Props> = ({
    name,
    points,
    status,
    date,
    isActive,
    goalId,
    score,
    approver,
    handleCycleStatus,
}: Props) => {
    const { saveType } = getGoalsSaveType(status);
    let text = 'N/A';
    if (saveType === 'GOAL') {
        text = 'Not Scored Yet';
    } else if (saveType == 'SCORE') {
        text = `Points - ${points}`;
    } else {
        if (status === 'COMPLETED') {
            text = `Points - ${points}`;
        }
    }

    return (
        <div
            className={`hor-cycle-status ${isActive ? 'active' : ''}`}
            onClick={() => status !== 'N/A' && handleCycleStatus && handleCycleStatus(name, status, goalId)}
        >
            <div className="main-section">
                <div className="section1">
                    <CircularProgressBarNew percentage={getPercentage(points, score)} status={status} score={score} />
                </div>
                <div className="section2">
                    <h5 className="quarter">{name}</h5>
                    <h6 className="points">{text}</h6>
                    <span className="horizontal-bar"></span>
                    <h6 className="status" style={{ color: CONSTANTS.STATUS_COLORS[status] }}>
                        {CONSTANTS.STATUS[status]}
                    </h6>
                    <h6 className="date">{formatDate(date)}</h6>
                </div>
            </div>
            <div className="sub-section">{approver && <h6>Approver : {approver}</h6>}</div>
        </div>
    );
};

export default HorizontalCycleStatus;
