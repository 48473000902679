import React, { useState } from 'react';
import { IEmployeeList } from '../../interfaces/DashboardInterface';
import TaskList from '../../components/teamStatus/TaskList';

import './TeamStatus.scss';

interface Props {
    heading: string;
    totalUsers: number;
    pendingUsers: number;
    employeeList: IEmployeeList[] | undefined;
    managerId: number;
    managerName: string;
}

const TeamStatus: React.FC<Props> = ({
    heading,
    totalUsers,
    pendingUsers,
    employeeList,
    managerId,
    managerName,
}: Props) => {
    const [show, setShow] = useState(false);
    const openTaskList = async () => {
        setShow(true);
        console.log(`show state ${show}`);
    };
    return (
        <div className="team-status">
            <div className="card">
                <h4>
                    {heading} <br />
                    <span className="text-muted" style={{ fontSize: '10px' }}>
                        Teams members pending submission.
                    </span>
                </h4>
                <div className="info">
                    <button
                        className="btn btn-link"
                        data-target=".bd-example-modal-lg"
                        data-toggle="modal"
                        type="button"
                        onClick={openTaskList}
                    >
                        <h1>{pendingUsers}</h1>
                    </button>
                    <span className="horizontal-line"></span>
                    <h1 className="total">{totalUsers}</h1>
                </div>
            </div>
            <TaskList
                show={show}
                setShow={setShow}
                employeeList={employeeList}
                managerId={managerId}
                managerName={managerName}
            />
        </div>
    );
};

export default TeamStatus;
