import React, { useState, useEffect } from 'react';
import { Pagination } from 'react-bootstrap';
import './Pagination.scss';

interface Props {
    pageNumber: number;
    totalCount: number;
    limit: number;
    setPageNumber: React.Dispatch<React.SetStateAction<number>>;
}
interface PageSize {
    start: number;
    end: number;
}
const RANGE = 5;
const PaginationComponent: React.FC<Props> = ({ limit, pageNumber, totalCount, setPageNumber }: Props) => {
    const [pageSize, setPageSize] = useState({ start: 0, end: 5 });
    const totalNumberOfPages = Math.ceil(totalCount / limit);
    const numbers = totalNumberOfPages == 0 ? [] : Array.from({ length: totalNumberOfPages }, (v, k) => k + 1);

    const paginate = (page: number) => {
        const size = handlePageSize(page);
        setPageSize(size);
        setPageNumber(page);
    };

    const handlePageSize = (page: number): PageSize => {
        const position = Math.ceil(RANGE / 2);
        if (page - position <= 0) {
            return { start: 0, end: RANGE };
        } else if (page + (RANGE - position) >= totalNumberOfPages) {
            return { start: totalNumberOfPages - RANGE, end: totalNumberOfPages };
        } else {
            return { start: page - position, end: page + (RANGE - position) };
        }
    };

    useEffect(() => {
        paginate(pageNumber);
    }, [pageNumber]);

    return (
        <div className="pagination">
            <Pagination>
                <Pagination.First onClick={() => paginate(1)} disabled={pageNumber == 1} />
                <Pagination.Prev onClick={() => paginate(pageNumber - 1)} disabled={pageNumber == 1} />
                {numbers.slice(pageSize.start, pageSize.end).map((item) => (
                    <Pagination.Item key={item} active={item === pageNumber} onClick={() => paginate(item)}>
                        {item}
                    </Pagination.Item>
                ))}
                <Pagination.Next onClick={() => paginate(pageNumber + 1)} disabled={pageNumber == totalNumberOfPages} />
                <Pagination.Last
                    onClick={() => paginate(totalNumberOfPages)}
                    disabled={pageNumber == totalNumberOfPages}
                />
            </Pagination>
        </div>
    );
};

export default PaginationComponent;
