import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './SubmitModal.scss';

interface Props {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
    submitForm: () => void;
    modalTitle: string;
    submitBtnText: string;
}

const SubmitModal: React.FC<Props> = ({ showModal, setShowModal, submitForm, modalTitle, submitBtnText }) => {
    let modalQuestion = '';
    const delegateFeedbackText = 'Are you sure to delegate the feedback';
    const revokeFeedbackText = 'Are you sure to revoke the delegation';
    const submitFeedbackText = 'Are you sure to submit & share the feedback';
    const submitManagerText = 'Are you sure to submit data to your Manager';
    const closeFeedbackText = 'Are you sure to save and submit the feedback';
    if (submitBtnText === 'Delegate') {
        modalQuestion = delegateFeedbackText;
    } else if (submitBtnText === 'Revoke') {
        modalQuestion = revokeFeedbackText;
    } else if (submitBtnText === 'Submit') {
        modalQuestion = submitFeedbackText;
    } else if (submitBtnText === 'Submit To Manager') {
        modalQuestion = submitManagerText;
    } else {
        modalQuestion = closeFeedbackText;
    }
    return (
        <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="submit-modal"
            animation={false}
        >
            <div className="title">
                <h4>{modalTitle}</h4>
            </div>
            <div className="modal-body">
                <p>{modalQuestion} ?</p>
            </div>
            <div className="modal-buttons">
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Cancel
                </Button>
                <Button variant="primary" type="submit" onClick={() => submitForm()}>
                    {submitBtnText}
                </Button>
            </div>
        </Modal>
    );
};

export default SubmitModal;
