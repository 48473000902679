import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import './Header.scss';

interface ModifyProps {
    onHide: () => void;
    show: boolean;
    handleAlert: () => void;
}

const LogOutModal: React.FC<ModifyProps> = ({ onHide, show, handleAlert }: ModifyProps) => {
    return (
        <Modal show={show} onHide={onHide} className="modify-cycle-modal">
            <Modal.Header closeButton>
                <Modal.Title>Logout</Modal.Title>
            </Modal.Header>
            <Modal.Body>Do you want to logout?</Modal.Body>
            <Modal.Footer>
                <Button
                    variant="outline-secondary"
                    onClick={() => {
                        onHide();
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant="primary"
                    onClick={() => {
                        handleAlert();
                    }}
                >
                    LogOut
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default LogOutModal;
