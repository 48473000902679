import React, { useState, useEffect, useRef } from 'react';

import { IGoalAction } from '../../../interfaces';
import { numberRowsInTextarea } from '../../../utils';
import useAutosizeTextArea from '../useAutosizeTextArea';

interface Props {
    indexKey: number;
    data: string;
    disabled: boolean;
    validate: boolean;
    readonly: boolean;
    dispatch?: React.Dispatch<IGoalAction>;
}

const FullTarget: React.FC<Props> = ({ indexKey, data, disabled, validate, readonly, dispatch }: Props) => {
    const [error, setError] = useState(false);
    const fullTargetInput = useRef<HTMLTextAreaElement>(null);
    const [value, setValue] = useState('');
    useAutosizeTextArea(fullTargetInput.current, value);

    const handleChange = (value: string) => {
        dispatch &&
            dispatch({
                type: 'CHANGE_FULL_TARGET',
                key: indexKey,
                value: value,
            });

        if (!value) {
            setError(true);
        } else {
            setValue(value);
            setError(false);
        }
    };

    useEffect(() => {
        if (validate && !data) {
            setError(true);
        }
    }, [validate]);

    useEffect(() => {
        if (null !== fullTargetInput.current) {
            fullTargetInput.current.rows = numberRowsInTextarea(fullTargetInput.current.scrollHeight);
        }
    }, [data]);

    return (
        <textarea
            className={`form-control ${error ? 'invalid' : ''}`}
            name="fullTarget"
            id="fullTarget"
            autoComplete="off"
            value={data}
            readOnly={readonly}
            disabled={disabled}
            cols={22}
            rows={1}
            onChange={(e) => handleChange(e.target.value)}
            ref={fullTargetInput}
        ></textarea>
    );
};

export default FullTarget;
