import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { storUrlPathInLocalStorage } from './utils';

interface PrivateProps extends RouteProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: any;
    isAuthenticated: boolean;
    userId: number;
}
const PrivateRoute: React.FC<PrivateProps> = ({
    component: Component,
    isAuthenticated,
    userId,
    ...rest
}: PrivateProps) => {
    storUrlPathInLocalStorage();
    return (
        <Route
            {...rest}
            render={(props) => (isAuthenticated && userId ? <Component {...props} /> : <Redirect to={`/signin`} />)}
        />
    );
};

interface PublicProps extends RouteProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: any;
    isAuthenticated: boolean;
    restricted: boolean;
    handleAuthentication: (token: string) => void;
}

const PublicRoute: React.FC<PublicProps> = ({
    component: Component,
    isAuthenticated,
    restricted,
    handleAuthentication,
    ...rest
}: PublicProps) => {
    const path = localStorage.getItem('path');
    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated && restricted ? (
                    <Redirect to={path ? path : '/dashboard'} />
                ) : (
                    <Component {...props} handleAuthentication={handleAuthentication} />
                )
            }
        />
    );
};

export { PrivateRoute, PublicRoute };
