import React, { useState, useEffect, useRef } from 'react';

import { IGoalAction } from '../../../interfaces';
import { numberRowsInTextarea } from '../../../utils';
import useAutosizeTextArea from '../useAutosizeTextArea';

interface Props {
    indexKey: number;
    data: string;
    disabled: boolean;
    validate: boolean;
    readonly: boolean;
    isBinary: boolean;
    dispatch?: React.Dispatch<IGoalAction>;
}

const ZeroTarget: React.FC<Props> = ({ indexKey, data, disabled, validate, readonly, isBinary, dispatch }: Props) => {
    const [error, setError] = useState(false);
    const zeroTargetInput = useRef<HTMLTextAreaElement>(null);
    const [zeroTarget, setzeroTarget] = useState('');
    useAutosizeTextArea(zeroTargetInput.current, zeroTarget);
    const handleChange = (value: string) => {
        dispatch &&
            dispatch({
                type: 'CHANGE_ZERO_TARGET',
                key: indexKey,
                value: value,
            });

        if (!value && !isBinary) {
            setError(true);
        } else {
            setzeroTarget(value);
            setError(false);
        }
    };

    useEffect(() => {
        if (validate && !data && !isBinary) {
            setError(true);
        }
        if (isBinary) {
            setError(false);
            dispatch &&
                dispatch({
                    type: 'CHANGE_ZERO_TARGET',
                    key: indexKey,
                    value: '',
                });
        }
    }, [validate, isBinary]);

    useEffect(() => {
        if (null !== zeroTargetInput.current) {
            zeroTargetInput.current.rows = numberRowsInTextarea(zeroTargetInput.current.scrollHeight);
        }
    }, [data]);

    return (
        <textarea
            className={`form-control ${error ? 'invalid' : ''}`}
            name="zeroTarget"
            id="zeroTarget"
            autoComplete="off"
            value={data}
            cols={22}
            rows={1}
            readOnly={readonly}
            disabled={isBinary || disabled}
            onChange={(e) => handleChange(e.target.value)}
            ref={zeroTargetInput}
        ></textarea>
    );
};

export default ZeroTarget;
