import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import GoalsRoutes from './GoalsRoutes';
import { useInitializeGA } from './hooks/useGoogleAnalytics';
import './App.scss';
import { HealthCheck, NotFound, Unauthorized } from './components';

const App: React.FC = () => {
    useEffect(() => {
        useInitializeGA();
    }, []);

    return (
        <Router basename={process.env.PUBLIC_URL}>
            <Switch>
                <Route exact path="/unauthorized" component={Unauthorized} />
                <Route exact path="/not-found" component={NotFound} />
                <Route exact path="/health-check" component={HealthCheck} />
                <Route path="/" component={GoalsRoutes}></Route>
            </Switch>
        </Router>
    );
};
export default App;
